import * as THREE from 'three';
import WebGL from 'three/examples/jsm/capabilities/WebGL.js';
import { Viewer } from './viewer.js';

export const TOUCH_DEVICE_BREAKPOINT_IN_PX = 1366;

window.addEventListener('load', () => {
  window.VIEWER = {};

  if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
    console.error('The File APIs are not fully supported in this browser.');
  } else if (!WebGL.isWebGLAvailable()) {
    console.error('WebGL is not supported in this browser.');
  }

  const { degToRad } = THREE.MathUtils;

  let target = new THREE.Vector3();
  let oldTarget = new THREE.Vector3();
  let oldDirection = new THREE.Vector3();
  let robotReachedBottomLimit = false;
  let cursorX = 0;
  let cursorY = 0;
  let yScrollOnFooter = null;

  const options = {
    kiosk: false,
    model: '',
    preset: '',
    cameraPosition: [0, 0, -100],
  };

  const viewerEl = document.createElement('div');
  document.body.appendChild(viewerEl);

  addElementsToAboutSection();

  viewerEl.classList.add('viewer');

  const viewer = new Viewer(viewerEl, options);

  viewer.update = () => {
    if (!robot || isTouchDevice()) return;

    robot.getWorldPosition(oldTarget);
    robot.getWorldDirection(oldDirection);

    oldTarget.addScaledVector(oldDirection, 0.1);
    oldTarget.z = target.z;

    target.lerp(oldTarget, 0.9);
    robot.lookAt(target);
  };

  view();

  let robot;

  function view() {
    if (viewer) viewer.clear();

    const modelPath = 'https://d1d4l3g6f8zuyp.cloudfront.net/assets/models/S_Bot_2.glb';

    viewer
      .load(modelPath)
      .catch(e => console.log(e))
      .then(gltf => {
        robot = gltf.scene.children[0];

        if (isTouchDevice()) {
          robot.rotation.set(degToRad(180), degToRad(15), degToRad(180));
        }

        const botSkinMaterial = robot.children[2].material;
        const botSkinMaterialClone = botSkinMaterial.clone();

        botSkinMaterialClone.depthWrite = false;
        botSkinMaterialClone.transparent = true;

        gltf.scene.children[0].children[1].renderOrder = 1;
        gltf.scene.children[0].children[1].material = botSkinMaterialClone;

        botSkinMaterial.depthWrite = true;
        botSkinMaterial.transparent = true;

        viewer.onResize();
      });
  }

  function onMouseMove(event) {
    if (!robot) return;
    const { clientWidth, clientHeight } = document.body;
    const { pageX, pageY } = event;

    cursorX = pageX;
    cursorY = pageY;

    const elem = document.body,
      boundingRect = elem.getBoundingClientRect(),
      x = event.clientX * (clientWidth / window.innerWidth),
      y = event.clientY * (clientHeight / window.innerHeight);

    const vector = new THREE.Vector3((x / clientWidth) * 2 - 1, -(y / clientHeight) * 2 + 1, 0.5);

    vector.unproject(viewer.activeCamera);

    vector.y += 0.02;
    vector.z = -0.08;

    target = vector;

    moveRobot(cursorX, cursorY);
  }

  function onScroll() {
    moveRobot(cursorX, window.scrollY + window.innerHeight / 2);
  }

  let lastTween;
  let robotSideOnTheScreen = 'left';

  function moveRobot(x, y) {
    if (isTouchDevice() || robotReachedBottomLimit) return;
    if (yScrollOnFooter && window.scrollY > yScrollOnFooter) return;

    if (lastTween) lastTween.kill();
    const canvasEl = viewer.renderer.domElement;

    let left;

    if (robotSideOnTheScreen === 'left') {
      left = Math.min(x, window.innerWidth * 0.1);
    } else {
      left = Math.max(x, window.innerWidth * 0.85);
      left = Math.min(left, window.innerWidth * 0.95);
    }

    lastTween = TweenLite.to(viewerEl, 2, {
      css: {
        left: lerp(left, viewerEl.offsetLeft, 0),
        top: lerp(y - canvasEl.height / 2, viewerEl.offsetTop, 0),
      },
      delay: 0,
    });

    viewer.lastTween = lastTween;
  }

  // Get the linear interpolation between two value
  function lerp(value1, value2, amount) {
    amount = amount < 0 ? 0 : amount;
    amount = amount > 1 ? 1 : amount;
    return value1 + (value2 - value1) * amount;
  }

  const observerOptions = {
    threshold: 0.3,
  };

  // Start observing sections to move the robot to left and right
  const about_section = document.querySelector('#about');
  const clients_section = document.querySelector('#clients');
  const our_work_section = document.querySelector('#work');
  const services_section = document.querySelector('#services');
  const our_team = document.querySelector('#services + div[data-token]');
  const contact_section = document.querySelector('#contact');
  const footer = document.querySelector('#footer');

  our_team.setAttribute('id', 'our_team');

  const observer = new IntersectionObserver((entries, observer) => {
    const sectionsSides = {
      about: 'right',
      clients: 'left',
      work: 'right',
      services: 'left',
      our_team: 'right',
      contact: 'left',
      footer: 'left',
    };

    entries.forEach(entry => {
      const sectionId = entry.target.id;

      if (!entry.isIntersecting) return;
      robotSideOnTheScreen = sectionsSides[sectionId] || 'left';
    });
  }, observerOptions);

  const footerObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !yScrollOnFooter) {
          yScrollOnFooter = window.scrollY;
        }

        robotReachedBottomLimit = entry.isIntersecting;
      });
    },
    { rootMargin: '500px' }
  );

  observer.observe(about_section);
  observer.observe(clients_section);
  observer.observe(our_work_section);
  observer.observe(services_section);
  our_team && observer.observe(our_team);
  observer.observe(contact_section);

  footerObserver.observe(footer);

  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('scroll', onScroll);

  function isTouchDevice() {
    return window.innerWidth < TOUCH_DEVICE_BREAKPOINT_IN_PX;
  }

  function addElementsToAboutSection() {
    const about_section = document.querySelector('#about');

    const touch_device_robot_container = document.createElement('div');
    const robot_shadow_container = document.createElement('div');

    touch_device_robot_container.setAttribute('id', 'touch_device_robot_container');

    robot_shadow_container.classList.add('robot_shadow_container');
    robot_shadow_container.innerHTML = '<div class="robot_shadow"></div>';

    about_section.insertBefore(robot_shadow_container, about_section.firstChild);
    about_section.insertBefore(touch_device_robot_container, about_section.firstChild);
  }
});
